<template>
  <div>
    <h3>{{ urlName }}</h3>
  </div>
  <div id="frame">
    <div id="map" class="box"></div>
  </div>
    <div>
    <h3>住所：{{ urlAddress }}</h3>
  </div>
  <button type="button" class="clickButton" @click="linkToGoogleMap()">Googleマップで開く</button>
</template>

<script>
import "leaflet/dist/leaflet.css";
import axios from "axios";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import storeIcon from "../assets/finalchoice.png"
export default {
  data() {
    return {
      map: "",
      urlLat: "",
      urlLon: "",
      urlName: "",
      urlAddress: "",
      storeId: "",
      storeInfo: [],
      googleMapUrl: "",
      baseMapUrl: "https://www.google.com/maps/search/?api=1&query="
    };
  },
  mounted() {
    //urlパラメータから位置情報、店名を取得
    // this.urlLat = this.$route.query.lat || '';
    // this.urlLon = this.$route.query.lon || '';
    // this.urlName = this.$route.query.name || '';
    // this.urlAddress = this.$route.query.address || '';
    this.storeId = this.$route.query.storeId || '';

    console.log(this.storeId);
    //店情報がないならトップに戻る
    if(this.storeId === '') {
      this.$router.push({path: '/'});
    }else{
      //店情報取得APIを叩く
      let apiurl = "https://jov6ym4yl7.execute-api.ap-northeast-1.amazonaws.com/prod/get-store-info";
      axios.post(apiurl, { storeId : this.storeId }).then((response) => {
        this.storeInfo = JSON.parse(JSON.stringify(response.data.body));
        this.storeInfo = JSON.parse(this.storeInfo)["results"]["shop"];
        this.urlLat = this.storeInfo[0].lat;
        this.urlLon = this.storeInfo[0].lng;
        this.urlName = this.storeInfo[0].name;
        this.urlAddress = this.storeInfo[0].address;

        this.googleMapUrl = this.baseMapUrl + this.urlLat + "," + this.urlLon;
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position)=>{
            this.initMap(position);
            console.log(this.map);
          });
        } else {
          alert("本ブラウザではGeolocationが使えません");
        }
      }).catch(err => console.log(err));
    }
  },
  beforeUnmount: function (){
    if(this.map !== ""){
      this.onDestroy();
    }

  },
  methods: {
    initMap(position) {
      // vueとの相性で表示がずれるためマーカーの表示位置調整
      let DefaultIcon = L.icon({
        iconUrl: icon,
        iconRetinaUrl: iconRetina,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41],
      });
      //店用アイコン(final-choiceロゴ)
      let CurrentLocationIcon = L.icon({
        iconUrl: storeIcon,
        iconSize: [40, 40],
        iconAnchor: [5, 30],
        popupAnchor: [0, -50],
        shadowSize: [40, 40],
      });

      L.Marker.prototype.options.icon = DefaultIcon;

      this.map = L.map("map", {
        center: L.latLng(this.urlLat, this.urlLon),
        zoom: 17, //拡大レベルの設定
      });
      // 表示したい地図のurlを用意する。
      L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png").addTo(this.map);
      //店マーカーの表示
      L.marker([this.urlLat, this.urlLon],{icon: CurrentLocationIcon}).addTo(
        this.map
      );
      //現在地マーカーの表示
      L.marker([position.coords.latitude, position.coords.longitude]).addTo(
        this.map
      );
    },
    linkToGoogleMap() {
      window.open(this.googleMapUrl, '_blank')
    },
    onDestroy() {
      this.map.off();
      this.map.remove();
      this.map = null;
    }
  },
};
</script>

<style>
#frame {
height: 0;
overflow: hidden;
padding-bottom: 100%;
position: relative;
z-index: 0;
}
#map {
position: absolute;
left: 0;
top: 0;
height: 100%;
width: 100%;
}

.clickButton{
  width : 160px;
  margin : 8px 0px;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border-radius: 4px;
  color: #ffffff;
  background-color: #e88b01;
  border: 3px solid #e88b01;
}

.btn-gradient-3d-orange:active {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  border-bottom: none;
}

</style>
