<template>
  <div>Dashboard</div>

  <p>
    ページ追加は下記ページ参照<br>
    （コマンドでページ追加は今もなさそう？）<br>
    <a href="https://qiita.com/yuta-38/items/9def9264f1ea5cf8521d">https://qiita.com/yuta-38/items/9def9264f1ea5cf8521d</a>
  </p>

  <p>
    ページ遷移は &lt;router-link :to=&quot;...&quot;&gt; <!-- <router-link :to="..."> -->を使います。<br>
    <a href="https://router.vuejs.org/ja/guide/">https://router.vuejs.org/ja/guide/</a><br>
    jsでページ遷移は router.push(...) を使います。<br>
    <a href="https://router.vuejs.org/ja/guide/essentials/navigation.html">https://router.vuejs.org/ja/guide/essentials/navigation.html</a>
  </p>
  <p>
    &lt;a&gt; タグも使えるが、少し遅くなるかも？<br>
  （Vue内のページ遷移では非推奨、外部ページへのリンクは &lt;a&gt; タグOK）
  </p>

  サンプル: <br>
  <router-link to="/">Home</router-link><br>
  <a href="/">Home（aタグ）</a>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>

<style scoped>

</style>