<template>
  <div class="user">
    <h1>This is an user page</h1>
  </div>
</template>

<script>
/* Import the Amplify API category */
import { API } from 'aws-amplify';

export default {
  name: 'TestUser',
  data() {
    return {
      counter: 0
    }
  },
  mounted() {
    console.log("mounted")

    createUser()
    async function createUser() {
      const data = {
        body: {
          name: 'Chris',
          phone: '+1-555-555-5555'
        }
      };
      const apiData = await API.post('finalchoiceapi', '/user', data);
      console.log({ apiData });
    }

    fetchUsers()
    async function fetchUsers() {
      const contactData = await API.get('finalchoiceapi', '/user');
      console.log({ contactData });
    }
  }
}
</script>

<style scoped>

</style>